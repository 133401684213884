import React from "react";
import "./FirstSection.scss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

type Props = {};

const FirstSection = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div className="video-parent-wrapper">
      <div className="text">Coffee revealed</div>
      <video
        className="video"
        id="video"
        autoPlay
        loop
        controls={false}
        playsInline
        muted
        preload="auto"
      >
        <source src="https://baristretto-bucket.s3.eu-central-1.amazonaws.com/misc/video2-1920.mp4" />
      </video>
      <Button
        variant="outlined"
        color="success"
        size="large"
        className="hero-button"
        onClick={() => navigate(`/explore`)}
      >
        Explore
      </Button>
    </div>
  );
};

export default FirstSection;
